import { render, staticRenderFns } from "./Primary.vue?vue&type=template&id=133e0c2a&scoped=true&"
import script from "./Primary.vue?vue&type=script&lang=js&"
export * from "./Primary.vue?vue&type=script&lang=js&"
import style0 from "./Primary.vue?vue&type=style&index=0&id=133e0c2a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "133e0c2a",
  null
  
)

export default component.exports