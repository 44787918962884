import { render, staticRenderFns } from "./MailHead.vue?vue&type=template&id=39c55dc3&scoped=true&"
import script from "./MailHead.vue?vue&type=script&lang=js&"
export * from "./MailHead.vue?vue&type=script&lang=js&"
import style0 from "./MailHead.vue?vue&type=style&index=0&id=39c55dc3&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39c55dc3",
  null
  
)

export default component.exports